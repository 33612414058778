.uk-maxwidth-1-1 {
    max-width: 100%;
}

.uk-maxwidth-1-3 {
  max-width: 33%;
}

.uk-maxwidth-2-3 {
  max-width: 66%;
}

.uk-minwidth-1-1 {
  min-width: 100%;
}

.uk-minwidth-1-3 {
  min-width: 33%;
}

.uk-minwidth-2-3 {
  min-width: 66%;
}
