$button-default-background: var(--button-default-background, $global-color);
$button-default-color: var(--button-default-color, $global-primary-color);
$button-default-hover-background: var(--button-default-hover-background, $global-color);
$button-default-hover-color: var(--button-default-hover-color, $global-emphasis-color);

$button-primary-background: var(--button-primary-background, $global-primary-color);
$button-primary-color: var(--button-primary-color, $global-color);
$button-primary-hover-background: var(--button-primary-hover-background, $global-secondary-color);
$button-primary-hover-color: var(--button-primary-hover-color, $global-secondary-color);

$button-secondary-background: var(--button-secondary-background, $global-secondary-color);
$button-secondary-color: var(--button-secondary-color, $global-color);
$button-secondary-hover-background: var(--button-secondary-hover-background, $global-color);
$button-secondary-hover-color: var(--button-secondary-hover-color, $global-color);

$button-font-family: $base-button-font-family;

@import '~uikit/src/scss/components/button';

.uk-button {font-family: $button-font-family; }
