.uk-rating {
  margin-top: $global-small-margin;
  border: none;
  display: inline;
  div {
    display: inline;
    &:has( ~ div label input:checked) label, // All labels preceding the checked input
    label:has(input:checked), // All labels with a checked input
    &:has( ~ div label:hover) label,  // All labels preceding the hovered label
    label:hover { // All labels that are hovered
      svg {
        fill: #FECE31;
      }
    }
    label {
      color: #90A0A3;
      input {
        display: none;
      }
    }
  }
  div:hover ~ div label svg {
    fill:transparent !important;
  }
}
