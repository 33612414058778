// NAVBAR COLORS
$navbar-background: $global-primary-color;  // COLORE SFONDO MENU
$navbar-logo-text: $global-emphasis-color; // COLORE TESTO LOGO
$navbar-nav-item-color: $global-color; // COLORE LINK MENU
$navbar-nav-item-hover-color: $global-secondary-color; //COLORE LINK MENU HOVER
$navbar-nav-item-active-color: $global-secondary-color; //COLORE LINK MENU ATTIVO
$navbar-nav-item-disabled-color: $global-muted-color; // COLORE LINK MENU DISATTIVATI
$navbar-dropdown-background: $global-color;  // COLORE SFONDO DROPDOWN
$navbar-dropdown-nav-item-color: $global-primary-color; // COLORE LINK SOTTOMENU
$navbar-dropdown-nav-item-hover-color: darken($global-primary-color, 20); //COLORE LINK SOTTOMENU HOVER
$navbar-dropdown-nav-item-active-color: darken($global-primary-color, 20); //COLORE LINK SOTTOMENU ATTIVO
// NAVBAR FONTS
$navbar-nav-item-font-family: $base-nav-item-font-family;
$navbar-nav-item-font-size: $global-medium-font-size;
// NAVBAR SPACING
$nav-item-active-text-decoration: none;
$navbar-nav-item-height: 25px;
$navbar-nav-item-text-transform: none;
$navbar-dropdown-padding: 5px;
$navbar-dropdown-margin: 0px;
$navbar-dropdown-width: 75px;
$dropdown-min-width: 50;

@import '~uikit/src/scss/components/navbar';

.uk-navbar-container{
  border-bottom-color: var(--header-border-color, $global-border);
  &:not(.uk-navbar-transparent) {
    background-color: var(--header-bg-color, $navbar-background);
  }
}

.uk-navbar {
  @if $header-background-image != 'null' {
    background-image: url($header-background-image);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .logo-image {
    margin: 2px;
  }

  .logo-text {
    font-family: $global-font-family;
    font-size: $global-small-font-size;
    color: var(--header-text-color, $navbar-logo-text);
    font-weight: 100;
    line-height: 1;
  }

  .uk-navbar-toggle-icon{
    z-index: 1001;
    color: var(--navbar-toggleicon-color, $navbar-item-color);
    &[aria-expanded=true]
    {
      color: var(--navbar-toggleicon-open-color, $navbar-item-color);
    }
  }

  .uk-navbar-nav>li {
    align-items: center;
    display: flex;

    >a,
    >span {
      font-size: $navbar-nav-item-font-size;
      margin: auto $global-margin;
      padding: 0;
    }

    >a:not([aria-expanded]):hover {
      border-bottom: $global-border-width solid $global-border;
    }

    >span {
      color: $navbar-nav-item-disabled-color;
    }

    &.uk-active>a:not([aria-expanded]) {
      border-bottom: $global-border-width solid $global-border;
    }
  }
}
